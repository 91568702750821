import {environment} from "../../../environments/environment";
import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Complexity} from "../models/complexity.model";

const COMPLEXITY_ROOT_URL: string = environment.backendCoreURL + "/goal-complexities";

@Injectable()
export class ComplexityService {

    constructor(private http: HttpClient) {
    }

    getComplexityList(): Observable<Complexity[]> {
        const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf8'});

        return this.http.get<Complexity[]>(COMPLEXITY_ROOT_URL, {headers: headers});
    }
}
