import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BaseGoalService} from "../../shared/services/base-goal.service";

@Component({
    selector: 'nm-goal-delete-dialog',
    templateUrl: './goal-delete-dialog.component.html',
    styleUrls: ['./goal-delete-dialog.component.scss']
})
export class GoalDeleteDialogComponent {

    goalName: string = this.data.goalName;

    goalId: string = this.data.goalId;

    constructor(
        private matDialogReference: MatDialogRef<boolean>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private baseGoalService: BaseGoalService
    ) {
    }

    confirmDelete(): void {
        this.baseGoalService.deleteById(this.goalId).subscribe();
        this.matDialogReference.close(true);
    }
}
