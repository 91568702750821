import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthComponent} from './auth.component';

import {LoginComponent} from './login/login.component';
import {RegistrationComponent} from './registration/registration.component';
import {ResetPasswordComponent} from "./reset-password/reset-password.component";
import {AuthTestComponent} from "./auth-test/auth-test.component";

const routes: Routes = [
    {
        path: '', component: AuthComponent, children: [
            {path: 'login', component: LoginComponent},
            {path: 'registration', component: RegistrationComponent},
            {path: 'reset-password', component: ResetPasswordComponent},
            {path: 'auth-test', component: AuthTestComponent},
        ]
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {
}
