import {AbstractControl, ValidatorFn} from "@angular/forms";

export function afterOrEqualCurrentDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let valid = true;
        if (control.value != null) {
            const validatedDate: Date = control.value;
            const currentDate: Date = new Date();
            currentDate.setHours(0, 0, 0, 0);

            if (validatedDate < currentDate) {
                valid = false;
            }
        }

        return valid ? null : {'afterOrEqualCurrentDateValidatorError': true};
    };
}

export function startFinishDateValidator(): ValidatorFn {
    return (group: AbstractControl): { [key: string]: any } | null => {
        let valid = true;
        const startDate = group.get("startDateForm")?.value;
        const finishDate = group.get("finishDateForm")?.value;
        if (startDate != null && finishDate != null) {
            if (startDate > finishDate) {
                valid = false;
            }
        }

        return valid ? null : {'startFinishDateValidator': true};
    };
}