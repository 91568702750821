import {HttpClient, HttpResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable()
export class AuthTestGreetingService {

    constructor(private httpClient: HttpClient) {
    }

    hello(): Observable<HttpResponse<string>> {
        return this.httpClient.get(environment.backendCoreURL + '/hello', {observe: 'response', responseType: "text"});
    }

    helloUser(): Observable<HttpResponse<string>> {
        return this.httpClient.get(environment.backendCoreURL + '/hello/user', {observe: 'response', responseType: "text"});
    }

    helloAdmin(): Observable<HttpResponse<string>> {
        return this.httpClient.get(environment.backendCoreURL + '/hello/admin', {observe: 'response', responseType: "text"});
    }
}
