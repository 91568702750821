import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AppComponent} from 'src/app/app.component';
import {ValidationConstants} from "../../shared/validation-constants";

const MIN_NAME_LENGTH: number = 2;
const MAX_NAME_LENGTH: number = 50;
const MIN_SURNAME_LENGTH: number = 2;
const MAX_SURNAME_LENGTH: number = 50;

@Component({
    selector: 'nm-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

    form!: FormGroup;

    passwordVisibility: boolean = false;

    constructor(private router: Router, private appComponent: AppComponent) {
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            'name': new FormControl(
                null,
                [
                    Validators.minLength(MIN_NAME_LENGTH),
                    Validators.maxLength(MAX_NAME_LENGTH),
                    Validators.pattern(ValidationConstants.ENG_RU_LETTERS_PATTERN)
                ]
            ),
            'surname': new FormControl(
                null,
                [
                    Validators.minLength(MIN_SURNAME_LENGTH),
                    Validators.maxLength(MAX_SURNAME_LENGTH),
                    Validators.pattern(ValidationConstants.ENG_RU_LETTERS_PATTERN)
                ]
            ),
            'email': new FormControl(
                null,
                [
                    Validators.required,
                    Validators.email,
                    Validators.minLength(ValidationConstants.MIN_EMAIL_LENGTH),
                    Validators.maxLength(ValidationConstants.MAX_EMAIL_LENGTH)
                ]
            ),
            'password': new FormControl(null, [Validators.required])
        });
    }

    onSubmit() {
        if (this.form.invalid) {
            this.appComponent.showError("Вы ввели неверные данные для регистрации");
        } else {
            this.appComponent.showError("Приложение находится на этапе разработки. Регистрация пользователя временно не работает.");
        }
    }

    changeVisibility() {
        this.passwordVisibility = !this.passwordVisibility;
    }

    cancel() {
        this.router.navigate(['/login']);
    }
}
