<div class="center-text">Восстановление пароля</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
        <input
            id="email"
            formControlName="email"
            type="text"
            placeholder="Введите электронную почту"
            [ngClass]="{
                'form-control': true,
                'auth-input': true,
                'error-border': form.controls['email'].invalid && form.controls['email'].touched
            }"
        >
        <span
            class="form-error-text"
            *ngIf="form.controls['email'].invalid && form.controls['email'].touched"
        >
            <img class="validation-error-icon" src="../../../assets/cross.svg" alt="error">
            Электронная почта введена неверно
        </span>
    </div>
    <div>
        <p class="bottom-label">Введите адрес электронной почты и мы вышлем вам ссылку для восстановления пароля.</p>
    </div>
    <div class="primary-secondary-horizontal-buttons-container">
        <div class="form-group">
            <button class="secondary-button" (click)="cancel()"> Отмена</button>
        </div>
        <div class="form-group">
            <button type="submit" class="primary-button"> Получить ссылку</button>
        </div>
    </div>
</form>

