<div class="progress-container">
    <div *ngIf="isGoal()" class="progress-text-div">

        <!-- Кнопка добавления прогресса -->
        <div class="flex-row-7gap-div">
            <button *ngIf="progressInputFlag == false && !isGoalComplete()"
                    class="secondary-button add-progress-button disabled-button"
                    (click)="showProgressInput()"
            >
                <img class="add-progress-img" src='assets/plus-icon.svg' alt="plus-icon">
            </button>
            <p *ngIf="progressInputFlag == false" class="progress-text">Прогресс</p>
        </div>
        <p *ngIf="progressInputFlag == false" class="progress-unit-text">({{progressData.measurementUnit.title}})</p>

        <!-- Поле ввода прогресса -->
        <div *ngIf="progressInputFlag == true" class="progress-input-div">
            <img src="assets/minus-icon.svg" (click)="decrementInputProgressValue()" class="decrement-icon"
                 alt="minus-icon">
            <input class="main-input progress-input"
                   type="number"
                   #progressInput
                   placeholder="1"
                   [(ngModel)]="progressInputValue"
                   (keydown)="validateProgressInputLength($event)"
                   (keyup)="calculateWidth(progressInput)"
            >
            <img src="assets/plus-icon.svg" (click)="incrementInputProgressValue()" class="increment-icon"
                 alt="plus-icon">
        </div>

        <!-- Кнопки формы ввода прогресса -->
        <div class="control-buttons-div">
            <button *ngIf="progressInputFlag == true" class="secondary-button accept-cancel-button"
                    (click)="addProgressInputValue()">
                <img class="accept-img" src="assets/checked.svg" alt="checked">
            </button>
            <button *ngIf="progressInputFlag == true" class="secondary-button accept-cancel-button"
                    (click)="cancel()">
                <img class="cancel-img" src="assets/cross-light.svg" alt="cross-light">
            </button>
        </div>
    </div>

    <div *ngIf="isMasterGoal()" class="progress-text-div">
        <img class="master-goal-img" src='assets/master-goal-icon.svg' alt="master-goal-icon">
        <p class="progress-text">Составная Цель</p>
    </div>

    <!-- Прогресс для превью-карточки -->
    <div *ngIf="!fullMode" id="short-progressbar">
        <div id="short-progressbar-value" [style.width.%]=this.progressPercent></div>
    </div>
    <div *ngIf="!fullMode" class="progress-values-div">
        <p class="progress-current-value">{{progressData.currentProgressValue}}</p>
        <p class="progress-max-value">{{progressData.finishProgressValue}}</p>
    </div>

    <!-- Прогресс для полной карточки -->
    <div *ngIf="fullMode" class="full-progress-div">
        <p class="progress-current-value">{{progressData.currentProgressValue}}</p>
        <div id="full-progressbar">
            <div id="full-progressbar-value" [style.width.%]=this.progressPercent>
                <p *ngIf="this.progressPercent > PROGRESS_PERCENT_TO_SWITCH_TEXT_POSITION"
                   class="full-progressbar-percent-text">
                    {{this.progressPercent}} %
                </p>
            </div>
            <p *ngIf="this.progressPercent <= PROGRESS_PERCENT_TO_SWITCH_TEXT_POSITION"
               class="full-progressbar-percent-text">
                {{this.progressPercent}} %
            </p>
        </div>
        <p class="full-progress-max-value">{{progressData.finishProgressValue}}</p>
    </div>
    <div *ngIf="fullMode" class="progress-dates-div">
        <p>{{progressData.startDate | date : 'dd.MM.yyyy'}}</p>
        <p>{{progressData.finishDate | date : 'dd.MM.yyyy'}}</p>
    </div>
</div>