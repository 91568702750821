import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Tag} from "../models/tag.model";
import {environment} from "../../../environments/environment";
import {PageableFilterRequest} from "../models/http/request/pageable-filter-request";
import {PageableResponse} from "../models/http/response/pageable/pageable-response";
import {TagFilter} from "../models/http/request/filter/tag-filter";

const TAG_ROOT_URL: string = environment.backendCoreURL + "/tags";

@Injectable()
export class TagService {

    constructor(private http: HttpClient) {
    }

    public getTagPageableByFilter(request: PageableFilterRequest<TagFilter>): Observable<PageableResponse<Tag>> {
        return this.http.get<PageableResponse<Tag>>(
            TAG_ROOT_URL + request.convertPageableToUrl()
        );
    }
}
