import {Tag} from "../tag.model";
import {MeasurementUnit} from "../measurement-unit.model";
import {GoalVisibility} from "./goal-visibility.model";
import {Complexity} from "../complexity.model";
import {BaseGoalStatus} from "../base-goal-status.model";

export class Goal {

    constructor(
        public id: string,
        public name: string,
        public description: string,
        public creationDate: Date,
        public startDate: Date,
        public finishDate: Date,
        public tagIds: Tag[],
        public currentProgressValue: number,
        public finishProgressValue: number,
        public measurementUnit: MeasurementUnit,
        public author: string,
        public completeDate: Date,
        public type: GoalType,
        public status: BaseGoalStatus,
        public visibility: GoalVisibility,
        public complexity: Complexity,
        public memberCount: number
    ) {
    }
}

export enum GoalStatus {
    PLANNED = "Запланирована",
    ACTIVE = "Активна",
    EXPIRED = "Просрочена",
    COMPLETE = "Завершена"
}

export enum GoalType {
    GOAL = "GOAL",
    MASTER_GOAL = "MASTER_GOAL",
    TASK = "TASK"
}