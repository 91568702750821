<form [formGroup]="createForm" (ngSubmit)="onSubmit()" id="create-form">

    <div id="head-div">
        <p class="title">Новая цель</p>
        <div class="toggle-button-container">
            <div #simpleGoal (click)="changeActiveGoalType(simpleGoal)"
                 [ngClass]="{'active-toggle-button' : activeGoalType}"
                 class="simple-goal-toggle-button"
            >
                <p class="toggle-button-text">Обычная</p>
            </div>
            <div #masterGoal (click)="changeActiveGoalType(masterGoal)"
                 [ngClass]="{'active-toggle-button' : !activeGoalType}"
                 class="master-goal-toggle-button"
            >
                <p class="toggle-button-text">Составная</p>
            </div>
        </div>
    </div>

    <div id="content-div">
        <div class="content-sub-div">
            <input id="name-input"
                   class="main-input"
                   placeholder="Название*"
                   formControlName="nameForm"
                   [ngClass]="{'error-border':
                                createForm.controls['nameForm'].invalid
                                && (createForm.controls['nameForm'].touched || createForm.controls['nameForm'].dirty)}"
            >
            <span class="form-error-text"
                  *ngIf="createForm.controls['nameForm'].invalid
                         && (createForm.controls['nameForm'].touched || createForm.controls['nameForm'].dirty)"
            >
                <p *ngIf="createForm.controls['nameForm'].errors?.['required']">Название должно быть заполнено</p>
                <p *ngIf="createForm.controls['nameForm'].errors?.['maxlength']">Название не должно превышать {{NAME_MAX_LENGTH}} символов</p>
            </span>
        </div>

        <div class="content-sub-div">
            <p class="input-description-p">Выберите теги для цели (не больше 3)</p>
            <nm-tag-picker [userTagSearchMode]="false" (onTagListChange)="updateTagList($event)"></nm-tag-picker>
        </div>

        <div class="content-sub-div">
            <p class="input-description-p">Когда планируете начать и завершить цель</p>
            <div id="datepicker-div">
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate class="main-input date-input" type="text" readonly placeholder="Дата начала*"
                           formControlName="startDateForm"
                           [ngClass]="{'error-border': createForm.controls['startDateForm'].invalid && createForm.controls['startDateForm'].touched}"
                    >
                    <input matEndDate class="main-input date-input" type="text" readonly placeholder="Дата завершения*"
                           formControlName="finishDateForm"
                           [ngClass]="{'error-border': createForm.controls['finishDateForm'].invalid && createForm.controls['finishDateForm'].touched}"
                    >
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon>
                        <img src='assets/calendar-event.svg' alt="calendar-event">
                    </mat-icon>
                </mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </div>

            <span class="form-error-text" *ngIf="createForm.controls['startDateForm'].invalid && createForm.controls['startDateForm'].touched">
                <p *ngIf="createForm.controls['startDateForm'].errors?.['afterOrEqualCurrentDateValidatorError']">
                    Дата начала выполнения не должна быть раньше текущей даты
                </p>
            </span>

            <span class="form-error-text" *ngIf="createForm.controls['finishDateForm'].invalid && createForm.controls['finishDateForm'].touched">
                <p *ngIf="createForm.controls['finishDateForm'].errors?.['required']">
                    Дата завершения должна быть заполнена
                </p>
                <p *ngIf="createForm.controls['finishDateForm'].errors?.['afterOrEqualCurrentDateValidatorError']">
                    Дата завершения не должна быть раньше текущей даты
                </p>
            </span>

            <span class="form-error-text" *ngIf="createForm.errors?.['startFinishDateValidator'] && createForm.controls['finishDateForm'].touched">
                Дата завершения не должна быть раньше даты начала выполнения
            </span>
        </div>

        <div class="content-sub-div">
            <p class="input-description-p">Прогресс цели - сколько сейчас и сколько нужно достигнуть</p>
            <div id="progress-div">
                <input class="main-input progress-input" type="number" placeholder="От"
                       (blur)="currentProgressValueSetDefault()"
                       formControlName="currentProgressValueForm"
                       [ngClass]="{'error-border':
                                createForm.controls['currentProgressValueForm'].invalid
                                && (createForm.controls['currentProgressValueForm'].touched || createForm.controls['currentProgressValueForm'].dirty)}"
                >
                <input class="main-input progress-input" type="number" placeholder="До*"
                       (input)="currentProgressValueSetDefault()"
                       formControlName="finishProgressValueForm"
                       [ngClass]="{'error-border':
                                createForm.controls['finishProgressValueForm'].invalid
                                && (createForm.controls['finishProgressValueForm'].touched || createForm.controls['finishProgressValueForm'].dirty)}"
                >
            </div>

            <span class="form-error-text"
                  *ngIf="createForm.controls['currentProgressValueForm'].invalid
                         && (createForm.controls['currentProgressValueForm'].touched || createForm.controls['currentProgressValueForm'].dirty)"
            >
                <p *ngIf="createForm.controls['currentProgressValueForm'].errors?.['min']">Прогресс 'От' не должен быть отрицательным числом</p>
            </span>

            <span class="form-error-text"
                  *ngIf="createForm.controls['finishProgressValueForm'].invalid
                         && (createForm.controls['finishProgressValueForm'].touched || createForm.controls['finishProgressValueForm'].dirty)"
            >
                <p *ngIf="createForm.controls['finishProgressValueForm'].errors?.['required']">Прогресс 'До' должен быть заполнен</p>
                <p *ngIf="createForm.controls['finishProgressValueForm'].errors?.['min']">Прогресс 'До' не должен быть отрицательным числом</p>
            </span>
        </div>

        <div class="content-sub-div">
            <p class="input-description-p">В чем измерять прогресс цели</p>
            <select formControlName="measurementUnitTitleForm">
                <option value="" selected disabled hidden>Единица измерения</option>
                <option *ngFor="let measurementUnit of measurementUnits"> {{measurementUnit.title}}</option>
            </select>
        </div>

        <div>
            <div id="description-textarea" class="textarea-div"
                 [ngClass]="{'error-border':
                                createForm.controls['descriptionForm'].invalid
                                && (createForm.controls['descriptionForm'].touched || createForm.controls['descriptionForm'].dirty)}"
            >
                <textarea class="main-textarea" placeholder="Описание" formControlName="descriptionForm"></textarea>
            </div>
            <span class="form-error-text"
                  *ngIf="createForm.controls['descriptionForm'].invalid
                         && (createForm.controls['descriptionForm'].touched || createForm.controls['descriptionForm'].dirty)"
            >
                <p *ngIf="createForm.controls['descriptionForm'].errors?.['maxlength']">
                    Описание не должно превышать {{DESCRIPTION_MAX_LENGTH}} символов
                </p>
            </span>
        </div>
    </div>

    <div id="buttons-div">
        <button class="main-button action-button" type="submit" [disabled]="createForm.invalid">
            <img src='assets/checked.svg' alt="checked" [ngClass]="{'img-disabled': createForm.invalid, 'button-img': true}">
            Создать
        </button>
        <button class="main-button action-button" mat-dialog-close>
            <img src='assets/cross-light.svg' alt="cross-light" class="button-img">
            Отмена
        </button>
    </div>
</form>
