<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
    <div class="head-row">
        <div id="header" class="header-div">
            <img class="goal-icon" src='assets/goal-icon.svg' alt="goal-icon">
            <p class="subtitle" *ngIf="!editMode">{{goal.name}}</p>

            <!-- редактирование имени цели -->
            <div class="name-edit-div" *ngIf="editMode">
                <input class="name-edit-input subtitle" formControlName="nameForm">
                <span class="form-error-text"
                      *ngIf="editForm.controls['nameForm'].invalid
                             && (editForm.controls['nameForm'].touched || editForm.controls['nameForm'].dirty)"
                >
                    <p *ngIf="editForm.controls['nameForm'].errors?.['required']">Название должно быть заполнено</p>
                    <p *ngIf="editForm.controls['nameForm'].errors?.['maxlength']">
                        Название не должно превышать {{NAME_MAX_LENGTH}} символов
                    </p>
                </span>
            </div>

            <!-- кнопка меню в заголовке -->
            <button class="secondary-button" [matMenuTriggerFor]="menu" *ngIf="!editMode" type="button">
                <img id="dots-img" src='assets/dots-icon.svg' alt="dots-icon">
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="edit()" [disabled]="isGoalComplete()" [title]="getDisabledEditButtonTitle()" type="button">
                    <img class="button-icon" src='assets/pencil-icon.svg' alt="pencil-icon">
                    <span>Редактировать</span>
                </button>
                <button mat-menu-item type="button" (click)="delete()">
                    <img class="button-icon" src='assets/trash-icon.svg' alt="trash-icon">
                    <span>Удалить</span>
                </button>
            </mat-menu>
        </div>
    </div>

    <div id="goal-data-div">
        <div id="data-left-column">

            <!-- отображение дат -->
            <div class="flex-row-50gap-div" *ngIf="!editMode">
                <div class="flex-row-10gap-div">
                    <p class="gray-text main-text">Дата начала:</p>
                    <p class="white-text main-text">{{goal.startDate | date : 'dd.MM.yyyy'}}</p>
                </div>
                <div class="flex-row-10gap-div">
                    <p class="gray-text main-text">Выполнить до:</p>
                    <p class="white-text main-text">{{goal.finishDate | date : 'dd.MM.yyyy'}}</p>
                </div>
            </div>

            <!-- редактирование дат -->
            <div class="edit-group-div" *ngIf="editMode">
                <p class="input-description-p">Когда планируете начать и завершить цель</p>
                <div class="flex-row-10gap-div">
                    <mat-date-range-input [rangePicker]="picker">
                        <input *ngIf="editMode" matStartDate class="main-input date-input" type="text" readonly
                               placeholder="Дата начала*"
                               formControlName="startDateForm"
                               [ngClass]="{'error-border': editForm.controls['startDateForm'].invalid && editForm.controls['startDateForm'].touched}"
                        >
                        <p class="gray-text main-text">Выполнить до:</p>
                        <input *ngIf="editMode" matEndDate class="main-input date-input" type="text" readonly
                               placeholder="Дата завершения*"
                               formControlName="finishDateForm"
                               [ngClass]="{'error-border': editForm.controls['finishDateForm'].invalid && editForm.controls['finishDateForm'].touched}"
                        >
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker">
                        <mat-icon class="mat-icon" matDatepickerToggleIcon>
                            <img src='assets/calendar-event.svg' alt="calendar-event">
                        </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </div>
                <span class="form-error-text"
                      *ngIf="editForm.controls['startDateForm'].invalid && editForm.controls['startDateForm'].touched"
                >
                    <p *ngIf="editForm.controls['startDateForm'].errors?.['required']">
                        Дата начала выполнения должна быть заполнена
                    </p>
                </span>
                <span class="form-error-text"
                      *ngIf="editForm.controls['finishDateForm'].invalid && editForm.controls['finishDateForm'].touched"
                >
                    <p *ngIf="editForm.controls['finishDateForm'].errors?.['required']">Дата завершения должна быть заполнена</p>
                </span>
                <span class="form-error-text"
                      *ngIf="editForm.errors?.['startFinishDateValidator'] && editForm.controls['finishDateForm'].touched"
                >
                    Дата завершения не должна быть раньше даты начала выполнения
                </span>
            </div>

            <!-- прогресс -->
            <div id="progress-div" class="flex-column-10gap-div">
                <div class="flex-row-10gap-div">
                    <p class="gray-text main-text">Прогресс</p>
                    <img class="help-icon" src="assets/help-icon.svg" alt="help-icon">
                </div>
                <nm-goal-progress-section
                        *ngIf="!editMode"
                        [fullMode]=true
                        [progressData]=goalProgressData
                        (onAddProgressValue)="refreshGoal()">
                </nm-goal-progress-section>

                <!-- редактирование прогресса и ЕИ -->
                <div class="edit-group-div" *ngIf="editMode">
                    <p class="input-description-p">Сколько сейчас и сколько нужно достигнуть</p>
                    <div class="flex-row-10gap-div">
                        <input class="main-input progress-input" type="number" placeholder="От"
                               (blur)="currentProgressValueSetDefault()"
                               formControlName="currentProgressValueForm"
                               [ngClass]="{'error-border':
                                editForm.controls['currentProgressValueForm'].invalid
                                && (editForm.controls['currentProgressValueForm'].touched || editForm.controls['currentProgressValueForm'].dirty)}"
                        >
                        <input class="main-input progress-input" type="number" placeholder="До*"
                               formControlName="finishProgressValueForm"
                               [ngClass]="{'error-border':
                                editForm.controls['finishProgressValueForm'].invalid
                                && (editForm.controls['finishProgressValueForm'].touched || editForm.controls['finishProgressValueForm'].dirty)}"
                        >
                    </div>
                    <span class="form-error-text"
                          *ngIf="editForm.controls['currentProgressValueForm'].invalid
                         && (editForm.controls['currentProgressValueForm'].touched || editForm.controls['currentProgressValueForm'].dirty)"
                    >
                        <p *ngIf="editForm.controls['currentProgressValueForm'].errors?.['min']">Прогресс 'От' не должен быть отрицательным числом</p>
                    </span>
                    <span class="form-error-text"
                          *ngIf="editForm.controls['finishProgressValueForm'].invalid
                         && (editForm.controls['finishProgressValueForm'].touched || editForm.controls['finishProgressValueForm'].dirty)"
                    >
                        <p *ngIf="editForm.controls['finishProgressValueForm'].errors?.['required']">Прогресс 'До' должен быть заполнен</p>
                        <p *ngIf="editForm.controls['finishProgressValueForm'].errors?.['min']">Прогресс 'До' не должен быть отрицательным числом</p>
                    </span>
                </div>

                <div class="edit-group-div" *ngIf="editMode">
                    <p class="input-description-p">В чем измерять</p>
                    <select class="measurement-unit-select" formControlName="measurementUnitTitleForm">
                        <option
                                *ngFor="let measurementUnit of measurementUnits"
                                [selected]="measurementUnit.title === editForm.get('measurementUnitTitleForm')?.value">
                            {{measurementUnit.title}}
                        </option>
                    </select>
                </div>
            </div>

            <!-- Описание -->
            <div id="description-div">
                <p class="gray-text main-text">Описание</p>
                <p id="description-text" *ngIf="!editMode && !isDescriptionBlank()">
                    {{goal.description}}
                </p>
                <p class="null-value-text" *ngIf="!editMode && isDescriptionBlank()">
                    Описание отсутствует
                </p>

                <!-- редактирование описания -->
                <div *ngIf="editMode">
                    <div id="description-textarea" class="textarea-div"
                         [ngClass]="{'error-border':
                                editForm.controls['descriptionForm'].invalid
                                && (editForm.controls['descriptionForm'].touched || editForm.controls['descriptionForm'].dirty)}"
                    >
                        <textarea class="main-textarea" placeholder="Описание" formControlName="descriptionForm"></textarea>
                    </div>
                    <span class="form-error-text"
                          *ngIf="editForm.controls['descriptionForm'].invalid
                         && (editForm.controls['descriptionForm'].touched || editForm.controls['descriptionForm'].dirty)"
                    >
                        <p *ngIf="editForm.controls['descriptionForm'].errors?.['maxlength']">
                            Описание не должно превышать {{DESCRIPTION_MAX_LENGTH}} символов
                        </p>
                    </span>
                </div>
            </div>

            <!-- участники -->
            <div class="flex-column-10gap-div" *ngIf="!editMode">
                <p class="gray-text main-text">Участники</p>
                <button id="add-goal-members-button" class="secondary-button" type="button">
                    <img id="add-goal-members-button-img" src='assets/plus-icon.svg' alt="plus-icon">
                    <p>Добавить участников</p>
                </button>
                <p class="null-value-text">Участники не были добавлены</p>
            </div>

            <!-- кнопки сохранения и отмены при редактировании -->
            <div id="buttons-div" *ngIf="editMode">
                <button class="main-button save-button" type="submit" [disabled]="editForm.invalid">
                    <img src='assets/checked.svg' alt="checked"
                         [ngClass]="{'img-disabled': editForm.invalid, 'button-img': true}">
                    Сохранить
                </button>
                <button class="main-button cancel-button" (click)="editMode = false" type="button">
                    <img src='assets/cross-light-red.svg' alt="cross-light-red" class="button-img">
                    Отмена
                </button>
            </div>
        </div>

        <div id="data-right-column">
            <div class="flex-column-10gap-div">

                <!-- отображение статуса -->
                <div class="flex-row-10gap-div" *ngIf="!editMode">
                    <p class="gray-text main-text">Статус:</p>
                    <p class="main-text white-text" [ngClass]="getTextClassByStatus()">{{goal.status.title}}</p>
                    <img class="help-icon" src="assets/help-icon.svg" alt="help-icon">
                </div>

                <!-- отображение даты завершения -->
                <div class="flex-row-10gap-div" *ngIf="!editMode && goal.completeDate != undefined">
                    <p class="gray-text main-text">Дата завершения:</p>
                    <p class="main-text complete-dates-text">{{goal.completeDate | date : 'dd.MM.yyyy'}}</p>
                </div>

                <!-- отображение области видимости -->
                <div class="flex-row-10gap-div" *ngIf="!editMode">
                    <p class="gray-text main-text">Кому видна:</p>
                    <div class="visibility-icon-div" [ngSwitch]="goal.visibility.name">
                        <img *ngSwitchCase="'PRIVATE'" class="visibility-icon"
                             src='assets/visibility/private-icon.svg' alt="private-icon">
                        <img *ngSwitchCase="'FRIEND'" class="visibility-icon"
                             src='assets/visibility/friend-icon.svg' alt="friend-icon">
                        <img *ngSwitchCase="'PUBLIC'" class="visibility-icon"
                             src='assets/visibility/public-icon.svg' alt="public-icon">
                    </div>
                    <p class="white-text main-text">{{goal.visibility.title}}</p>
                    <img class="help-icon" src="assets/help-icon.svg" alt="help-icon">
                </div>

                <!-- редактирование области видимости -->
                <div class="flex-column-10gap-div" *ngIf="editMode">
                    <div class="flex-row-10gap-div">
                        <p class="gray-text main-text">Кому видна</p>
                        <img class="help-icon" src="assets/help-icon.svg" alt="help-icon">
                    </div>
                    <select class="visibility-select" formControlName="visibilityForm">
                        <option
                                *ngFor="let visibility of goalVisibilities"
                                [selected]="visibility.title === editForm.get('visibilityForm')?.value">
                            {{visibility.title}}
                        </option>
                    </select>
                </div>

                <!-- отображение даты создания -->
                <div class="flex-row-10gap-div" *ngIf="!editMode">
                    <p class="gray-text main-text">Создана:</p>
                    <p class="white-text main-text">{{goal.creationDate | date : 'dd.MM.yyyy'}}</p>
                </div>
            </div>

            <!-- теги -->
            <div class="flex-column-10gap-div">
                <div class="flex-row-10gap-div">
                    <p class="gray-text main-text">Теги</p>
                    <img class="help-icon" src="assets/help-icon.svg" alt="help-icon">
                </div>

                <!-- отображение тегов -->
                <div class="flex-column-8gap-div" *ngIf="!editMode">
                    <div class="tag-div" *ngFor="let tag of goal.tagIds">
                        <div class="tag-name-div" title="{{tag.name}}">
                            <p class="tag-name">{{tag.name}}</p>
                        </div>
                    </div>
                    <p class="null-value-text" *ngIf="goal.tagIds == null || goal.tagIds.length === 0">
                        Теги не были добавлены
                    </p>
                </div>

                <!-- редактирование тегов -->
                <div class="flex-column-8gap-div" *ngIf="editMode">
                    <nm-tag-picker class="tag-picker"
                                   [userTagSearchMode]="false"
                                   [initialSelectedTags]="editedTagList"
                                   (onTagListChange)="updateTagList($event)">
                    </nm-tag-picker>
                </div>
            </div>

            <!-- отображение сложности -->
            <div class="flex-column-8gap-div" *ngIf="!editMode">
                <div class="flex-row-10gap-div">
                    <p class="gray-text main-text">Сложность</p>
                    <img class="help-icon" src="assets/help-icon.svg" alt="help-icon">
                </div>
                <div class="flex-row-10gap-div">
                    <div class="complexity-icon-div" [ngSwitch]="goal.complexity.name">
                        <img *ngSwitchCase="'SIMPLE'" class="complexity-icon"
                             src='assets/complexity/complexity-simple-icon.svg' alt="complexity-simple-icon">
                        <img *ngSwitchCase="'NORMAL'" class="complexity-icon"
                             src='assets/complexity/complexity-normal-icon.svg' alt="complexity-normal-icon">
                        <img *ngSwitchCase="'HARD'" class="complexity-icon"
                             src='assets/complexity/complexity-hard-icon.svg' alt="complexity-hard-icon">
                    </div>
                    <p class="white-text main-text">{{goal.complexity.title}}</p>
                </div>
            </div>

            <!-- редактирование сложности -->
            <div class="flex-column-8gap-div" *ngIf="editMode">
                <div class="flex-row-10gap-div">
                    <p class="gray-text main-text">Сложность</p>
                    <img class="help-icon" src="assets/help-icon.svg" alt="help-icon">
                </div>
                <select class="complexity-select" formControlName="complexityForm">
                    <option
                            *ngFor="let complexity of complexities"
                            [selected]="complexity.title === editForm.get('complexityForm')?.value">
                        {{complexity.title}}
                    </option>
                </select>
            </div>
        </div>
    </div>
</form>