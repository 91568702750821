import {MeasurementUnit} from "../measurement-unit.model";
import {GoalVisibility} from "./goal-visibility.model";
import {Complexity} from "../complexity.model";

export class GoalUpdateDto {

    constructor(
        public name: string,
        public description: string,
        public startDate: Date,
        public finishDate: Date,
        public tagIds: string[],
        public currentProgressValue: number,
        public finishProgressValue: number,
        public measurementUnit: MeasurementUnit | null,
        public visibility: GoalVisibility | null,
        public complexity: Complexity | null
    ) {
    }
}