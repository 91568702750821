import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

const BASE_GOAL_ROOT_URL: string = environment.backendCoreURL + '/base-goals';

@Injectable()
export class BaseGoalService {

    constructor(private httpClient: HttpClient) {
    }

    public deleteById(id: string) {
        return this.httpClient.delete(BASE_GOAL_ROOT_URL + "/" + id);
    }
}