import {CommonFilter} from "./common-filter";
import {PageableRequest} from "./pageable-request";

export class PageableFilterRequest<T extends CommonFilter> extends PageableRequest {

    filters?: T;

    public override convertPageableToUrl(): string {
        return super.convertPageableToUrl() + this.filters?.convertFilterToUrl();
    }
}