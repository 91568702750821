<p>auth-test works!</p>
<div>
    <button class="btn btn-primary" (click)="getGreetingAll()">Привет для всех</button>
</div>
<div>
    <button class="btn btn-primary" (click)="getGreetingUser()">Привет для пользователя</button>
</div>
<div>
    <button class="btn btn-primary" (click)="getAdminGreeting()">Привет для админа</button>
</div>
<div>
    <span>
        {{greetingMessage}} - сообщение
    </span>
</div>
