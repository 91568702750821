import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {MeasurementUnit} from "../models/measurement-unit.model";

const MEASUREMENT_UNIT_ROOT_URL: string = environment.backendCoreURL + "/measurement-units";

@Injectable()
export class MeasurementUnitService {

    constructor(private http: HttpClient) {
    }

    getMeasurementUnitList(): Observable<MeasurementUnit[]> {
        const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf8'});

        return this.http.get<MeasurementUnit[]>(MEASUREMENT_UNIT_ROOT_URL, {headers: headers});
    }
}
