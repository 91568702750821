<div class="head-row">
    <div class="header-div">
        <p class="title">Мои цели</p>
        <button id="add-goal-button" class="secondary-button" (click)="openDialogCreate()">
            <img src='assets/plus-icon.svg' alt="plus-icon">
        </button>
    </div>

    <div id="filters-div">
        <nm-tag-picker [userTagSearchMode]="true" (onTagListChange)="filterByTags($event)" class="tag-filter">
        </nm-tag-picker>

        <label id="sort-label" for="sort-select"> Сортировка по</label>
        <select id="sort-select" [(ngModel)]="selectedSortValue" (change)="changeSort()">
            <option *ngFor="let sortValue of sortValues" value="{{sortValue.value}}">{{sortValue.viewValue}}</option>
        </select>

        <button id="sort-button" class="secondary-button" (click)="changeSortDirection()">
            <img src='assets/sort-icon.svg' alt="sort-icon">
        </button>
    </div>
</div>

<div id="search-div">
    <img id="search-icon" src="assets/search-icon.svg" alt="search-icon">
    <input type="search" id="search-input" class="main-input" placeholder="Поиск">
</div>
<div id="goals-found-div">
    <p id="goals-found-text">Найдено:</p>
    <p id="goals-found-number">{{page.totalElements}}</p>
</div>

<div id="goals-div">
    <nm-goal-preview-card *ngFor="let goal of response.content" [goal]="goal" (onDelete)="deleteGoal()">
    </nm-goal-preview-card>
</div>

<!--TODO: Подставить реальные значения-->
<div id="paging">
    <mat-paginator class="my-paging"
                   [length]="page.totalElements"
                   [pageSize]="page.size"
                   [pageSizeOptions]="[8, 12, 16]"
                   (page)="handlePageEvent($event)">
    </mat-paginator>
</div>