import {CommonFilter} from "../common-filter";

export class GoalByTagsFilter implements CommonFilter {

    tagIds: string[] = [];

    public convertFilterToUrl(): string {
        let url: string = '';
        this.tagIds?.forEach(filterElem => {
            url += `&tagIds=${filterElem}`;
        });

        return url;
    }
}
