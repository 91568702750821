import {Component, OnInit} from '@angular/core';
import {TagService} from "../../shared/services/tag.service";
import {GoalService} from 'src/app/shared/services/goal.service';
import {PageableFilterRequest} from 'src/app/shared/models/http/request/pageable-filter-request';
import {GoalByTagsFilter} from 'src/app/shared/models/http/request/filter/goal-by-tags-filter';
import {MatDialog} from "@angular/material/dialog";
import {GoalCreateComponent} from "./goal-create/goal-create.component";
import {Sort} from "../../shared/models/sort";
import {GoalPreviewCard} from "../../shared/models/goal/goal-preview-card.model";
import {Tag} from "../../shared/models/tag.model";
import {PageableResponse} from "../../shared/models/http/response/pageable/pageable-response";
import {Page} from "../../shared/models/http/response/pageable/page";
import {PageEvent} from "@angular/material/paginator";
import {SortDirection} from "../../shared/models/http/request/pageable-request";

@Component({
    selector: 'nm-goal-list',
    templateUrl: './goal-list.component.html',
    styleUrls: ['./goal-list.component.scss']
})
export class GoalListComponent implements OnInit {

    response!: PageableResponse<GoalPreviewCard>;

    request: PageableFilterRequest<GoalByTagsFilter>;

    page!: Page;

    sortValues: Sort[] = [
        {value: 'creationDate', viewValue: 'Дате создания'},
        {value: 'name', viewValue: 'Названию'},
        // {value: '', viewValue: 'Дате заполнения прогресса'}, // TODO: 12.01.2024 добавить после реализации на бэке
        {value: 'finishDate', viewValue: 'Дата окончания'},
    ];

    selectedSortValue: string = this.sortValues[0].value;

    constructor(private tagService: TagService, private goalService: GoalService, private createDialog: MatDialog) {
        this.request = new PageableFilterRequest<GoalByTagsFilter>();
        this.request.sort = [this.selectedSortValue];
        this.request.sortDirection = SortDirection.DESC;
        this.request.filters = new GoalByTagsFilter();
    }

    ngOnInit(): void {
        this.getGoalList();
    }

    changeSortDirection() {
        this.request.changeSortDirection();
        this.getGoalList();
    }

    changeSort() {
        this.request.sort = [this.selectedSortValue];
        this.getGoalList();
    }

    openDialogCreate() {
        const createDialogReference = this.createDialog.open(GoalCreateComponent, {panelClass: 'my-panel'});
        createDialogReference.afterClosed()
            .subscribe((result: boolean) => {
                // TODO: 12.01.2024 добавить обработку ошибок
                if (result) {
                    this.getGoalList();
                }
            });
    }

    deleteGoal(): void {
        this.getGoalList();
    }

    private getGoalList(): void {
        this.goalService.getGoalsList(this.request)
            .subscribe(response => {
                this.response = response;
                this.page= response.page;
            });
    }

    filterByTags(tags: Tag[]) {
        this.request.filters!.tagIds = tags.map(tag => tag.id);
        this.getGoalList();
    }

    handlePageEvent(pageEvent: PageEvent) {
        this.request.size = pageEvent.pageSize;
        this.request.page = pageEvent.pageIndex;
        this.getGoalList();
    }
}
