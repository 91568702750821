import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from './auth/auth.module';
import {SharedModule} from './shared/shared.module';
import {SystemModule} from "./system/system.module";
import {CookiesInterceptor} from "./cookies-interceptor.service";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from "@angular/common";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AuthModule,
        HttpClientModule,
        SharedModule,
        SystemModule,
        BrowserAnimationsModule,
        CommonModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: CookiesInterceptor, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
