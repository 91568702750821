<div class="center-text">Новый аккаунт</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
        <input
            type="text"
            [ngClass]=
                    "{'form-control': true,
                    'auth-input': true,
                    'error-border': form.controls['name'].invalid && form.controls['name'].touched}"
            id="name"
            placeholder="Имя"
            formControlName="name"
        >
        <span
            class="form-error-text"
            *ngIf="form.controls['name'].invalid && form.controls['name'].touched"
        >
            <img class="validation-error-icon" src="../../../assets/cross.svg">
            Имя введено неверно
        </span>
    </div>
    <div class="form-group">
        <input
            type="text"
            [ngClass]=
                    "{'form-control': true,
                    'auth-input': true,
                    'error-border': form.controls['surname'].invalid && form.controls['surname'].touched}"
            id="surname"
            placeholder="Фамилия"
            formControlName="surname"
        >
        <span
            class="form-error-text"
            *ngIf="form.controls['surname'].invalid && form.controls['surname'].touched"
        >
            <img class="validation-error-icon" src="../../../assets/cross.svg">
            Фамилия введена неверно
        </span>
    </div>
    <div class="form-group">
        <input
            type="text"
            [ngClass]=
                    "{'form-control': true,
                    'auth-input': true,
                    'error-border': form.controls['email'].invalid && form.controls['email'].touched}"
            id="email"
            placeholder="Электронная почта*"
            formControlName="email"
        >
        <span
            class="form-error-text"
            *ngIf="form.controls['email'].invalid && form.controls['email'].touched"
        >
            <img class="validation-error-icon" src="../../../assets/cross.svg">
            Электронная почта введена неверно
        </span>
    </div>
    <div class="form-group">
        <div class="password">
            <input
                [type]="passwordVisibility ? 'type' : 'password'"
                [ngClass]=
                        "{'form-control': true,
                        'auth-input': true,
                        'error-border': form.controls['password'].invalid && form.controls['password'].touched}"
                id="password"
                placeholder="Создайте пароль*"
                formControlName="password"
            >
            <a (click)="changeVisibility()" [ngClass]="{'password-control' : true, 'view': passwordVisibility}"></a>
        </div>
        <span
            class="form-error-text"
            *ngIf="form.controls['password'].invalid && form.controls['password'].touched"
        >
            <img class="validation-error-icon" src="../../../assets/cross.svg">
            Пароль введён неверно
        </span>
    </div>
    <div class="primary-secondary-horizontal-buttons-container">
        <div class="form-group">
            <button class="secondary-button" (click)="cancel()"> Отмена </button>
        </div>
        <div class="form-group">
            <button type="submit" class="primary-button"> Создать аккаунт </button>
        </div>
    </div>
    <div>
        <p class="bottom-label">
            Регистрируясь, Вы соглашаетесь с
            <a class="soft-link" href="https://live.staticflickr.com/6232/6290821500_064d293aa0_b.jpg" target="_blank">Условиями использования</a>
            и
            <a class="soft-link" href="https://live.staticflickr.com/6232/6290821500_064d293aa0_b.jpg" target="_blank">Политикой конфиденциальности</a>.
        </p>
    </div>
</form>
