import {GoalStatus} from "../../shared/models/goal/goal.model";
import {BaseGoalStatus} from "../../shared/models/base-goal-status.model";
import {FormGroup} from "@angular/forms";

export class CommonUtil {

    public static getTextClassByBaseGoalStatus(status: BaseGoalStatus) {
        return {
            'expired-dates-text': status.title == GoalStatus.EXPIRED.toString(),
            'complete-dates-text': status.title == GoalStatus.COMPLETE.toString()
        };
    }

    public static isEmpty(form: FormGroup, formElementName: string): boolean {
        return form.get(formElementName)?.value == null || form.get(formElementName)?.value.length <= 0
    }
}