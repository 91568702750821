import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {SharedModule} from "../shared/shared.module";
import {AuthRoutingModule} from "./auth-routing.module";
import {AuthComponent} from "./auth.component";
import {LoginComponent} from './login/login.component';
import {RegistrationComponent} from './registration/registration.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {AuthService} from "./services/auth.service";
import { AuthTestComponent } from './auth-test/auth-test.component';
import {AuthTestGreetingService} from "./services/auth-test-greeting.service";

@NgModule({
    declarations: [
        LoginComponent,
        RegistrationComponent,
        AuthComponent,
        ResetPasswordComponent,
        AuthTestComponent
    ],
    imports: [
        CommonModule,
        AuthRoutingModule,
        SharedModule
    ],
    providers: [AuthService, AuthTestGreetingService]
})
export class AuthModule {
}
