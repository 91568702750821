<div class="center-text">Войти в профиль</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
        <input
            type="text"
            [ngClass]=
                "{'form-control': true,
                 'auth-input': true,
                 'error-border': form.controls['email'].invalid && form.controls['email'].touched}"
            [(ngModel)]="email"
            id="email"
            placeholder="Электронная почта"
            formControlName="email"
        >
        <span
            class="form-error-text"
            *ngIf="form.controls['email'].invalid && form.controls['email'].touched"
        >
            <img class="validation-error-icon" src="../../../assets/cross.svg">
            Электронная почта введена неверно
        </span>
    </div>
    <div class="form-group">
        <div class="password">
            <input
                [type]="passwordVisibility ? 'type' : 'password'"
                [ngClass]=
                    "{'form-control': true,
                    'auth-input': true,
                    'error-border': form.controls['password'].invalid && form.controls['password'].touched}"
                [(ngModel)]="password"
                id="password"
                placeholder="Введите пароль"
                formControlName="password"
            >
            <a (click)="changeVisibility()" [ngClass]="{'password-control' : true, 'view': passwordVisibility}"></a>
        </div>
        <span
            class="form-error-text"
            *ngIf="form.controls['password'].invalid && form.controls['password'].touched"
        >
            <img class="validation-error-icon" src="../../../assets/cross.svg">
            Пароль введён неверно
        </span>
    </div>
    <div class="remember-me-div">
        <input class="remember-me-box" type="checkbox" value="yes" id="remember" name="remember" >
        <label for="remember">запомнить меня</label>
    </div>
    <div class="form-group">
        <button type="submit" class="primary-button">Войти</button>
    </div>
    <div>
        <button class="secondary-button" (click)="register()">Создать аккаунт</button>
    </div>
    <div>
        <p class="forgot-pass">
           <a tabindex="0" class="forgot-pass-link" (click)="forgotPassword()">Забыли пароль?</a>
        </p>
    </div>
</form>
