export class GoalVisibility {

    constructor(public name: string, public title: string) {
    }

    public static getGoalVisibilityByTitle(goalVisibilities: GoalVisibility[], title: string): GoalVisibility | null {
        const result = goalVisibilities.find((goalVisibility) => goalVisibility.title === title);
        if (typeof result === "undefined") {
            return null;
        }

        return result;
    }
}