import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AppComponent} from 'src/app/app.component';
import {ValidationConstants} from "../../shared/validation-constants";
import {AuthService} from "../services/auth.service";

@Component({
    selector: 'nm-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    form!: FormGroup;
    passwordVisibility: boolean = false;
    email!: string;
    password!: string;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private appComponent: AppComponent,
                private authService: AuthService) {
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            'email': new FormControl(
                null,
                [
                    Validators.required,
                    Validators.email,
                    Validators.minLength(ValidationConstants.MIN_EMAIL_LENGTH),
                    Validators.maxLength(ValidationConstants.MAX_EMAIL_LENGTH)
                ]
            ),
            'password': new FormControl(null, [Validators.required])
        });
    }

    // TODO обсудить и добавить корректную обработку ошибок при логине
    onSubmit() {
        if (this.form.invalid) {
            this.appComponent.showError("Вы ввели неверную почту или пароль");
        } else {
            this.appComponent.hideError();
            this.router.navigate(['/system/goal-list']);

            // TODO uncomment for auth scenery
            // console.log("Before login");
            // this.auth();
        }
    }

    changeVisibility() {
        this.passwordVisibility = !this.passwordVisibility;
    }

    register() {
        this.router.navigate(['/registration']);
    }

    forgotPassword() {
        this.router.navigate(['/reset-password']);
    }

    private auth() {
        this.authService.login(this.email, this.password)
            .subscribe({
                next: ((response: any) => {
                    // TODO заменить роут на главную страницу после отладки авторизации
                    this.router.navigate(['/auth-test']);
                }),

                error: (error => {
                    console.log(error);
                })
            });
    }
}
