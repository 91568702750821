<div id="system-component">
    <div id="menu">
        <div id="icon-div">
            <a href="/system/goal-list">
                <img id="img-logo" src='assets/next-me-logo.svg' alt="next-me-logo">
            </a>
        </div>
        <div id="menu-buttons-div">
            <div class="buttons-group">
                <div class="button-div">
                    <button class="menu-button main-button" (click)="openSystemMainPage()">
                        <img class="button-icon" src='assets/menu/list-icon.svg' alt="list-icon">
                    </button>
                    <a href="/system/goal-list">
                        <p>Мои цели</p>
                    </a>
                </div>
                <div class="button-div">
                    <button class="menu-button main-button">
                        <img class="button-icon" src='assets/visibility/friend-icon.svg' alt="friend-icon">
                    </button>
                    <a>
                        <p>Друзья</p>
                    </a>
                </div>
                <div class="button-div">
                    <button class="menu-button main-button">
                        <img class="button-icon" src='assets/visibility/public-icon.svg' alt="public-icon">
                    </button>
                    <a>
                        <p>Все цели</p>
                    </a>
                </div>
            </div>
            <div class="buttons-group">
                <div class="button-div">
                    <button class="menu-button main-button">
                        <img class="button-icon" src='assets/menu/admin-panel-icon.svg' alt="admin-panel-icon">
                    </button>
                    <a>
                        <p>Админка</p>
                    </a>
                </div>
                <div class="button-div">
                    <button class="menu-button main-button">
                        <img class="button-icon" src='assets/menu/settings-icon.svg' alt="settings-icon">
                    </button>
                    <a>
                        <p>Настройки</p>
                    </a>
                </div>
                <div class="button-div">
                    <button class="menu-button main-button">
                        <img class="button-icon" src='assets/menu/user-profile-icon.svg' alt="user-profile-icon">
                    </button>
                    <a>
                        <p>Профиль</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div id="content">
        <router-outlet></router-outlet>
    </div>
</div>