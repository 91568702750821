import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable()
export class AuthService {

    constructor(private httpClient: HttpClient) {
    }

    login(login: string, password: string): Observable<any> {
        const body = new HttpParams()
            .set('login', login)
            .set('password', password);

        return this.httpClient.post(
            environment.kcAuthURL + '/auth/login',
            body.toString(),
            {
                headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
            });
    }
}
