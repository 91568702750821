import {Component} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'nm-system',
    templateUrl: './system.component.html',
    styleUrls: ['./system.component.scss']
})
export class SystemComponent {

    constructor(private router: Router) {
    }

    openSystemMainPage() {
        this.router.navigate(['/system/goal-list']);
    }
}
