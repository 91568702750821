import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {map, Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {Goal, GoalType} from "../models/goal/goal.model";
import {PageableResponse} from "../models/http/response/pageable/pageable-response";
import {PageableFilterRequest} from "../models/http/request/pageable-filter-request";
import {GoalByTagsFilter} from "../models/http/request/filter/goal-by-tags-filter";
import {GoalCreateDto} from "../models/goal/goal.create-dto";
import {GoalPreviewCard} from "../models/goal/goal-preview-card.model";
import {GoalProgressValueDto} from "../models/goal/goal-progress-value.dto";
import {GoalUpdateDto} from "../models/goal/goal.update-dto";

const GOAL_ROOT_URL: string = environment.backendCoreURL + '/goals';

@Injectable()
export class GoalService {

    constructor(private httpClient: HttpClient) {
    }

    public getGoalsList(request: PageableFilterRequest<GoalByTagsFilter>): Observable<PageableResponse<GoalPreviewCard>> {
        return this.httpClient.get<PageableResponse<GoalPreviewCard>>(
            GOAL_ROOT_URL + request.convertPageableToUrl()
        );
    }

    public createGoal(goalCreateDto: GoalCreateDto): Observable<Goal> {
        return this.httpClient.post<Goal>(GOAL_ROOT_URL, goalCreateDto);
    }

    public updateGoal(goalUpdateDto: GoalUpdateDto, id: string): Observable<Goal> {
        return this.httpClient.put<Goal>(GOAL_ROOT_URL + "/" + id, goalUpdateDto);
    }

    public getGoalById(id: string): Observable<Goal> {
        return this.httpClient.get<Goal>(GOAL_ROOT_URL + "/" + id)
            .pipe(
                map((goal: Goal) => {
                    goal.type = GoalType[goal.type as unknown as keyof typeof GoalType];

                    return goal;
                })
            );
    }

    public addProgressValue(id: string, progressValue: GoalProgressValueDto) {
        return this.httpClient.put(GOAL_ROOT_URL + "/" + id + "/add-progress", progressValue);
    }
}