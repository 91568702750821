import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'nm-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

    @Input('errorMessage')
    errorMessage: string | null = null;

    @Output()
    errorWindowHide = new EventEmitter<void>;

    hideError(): void {
        this.errorWindowHide.emit();
    }
}
