import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {GoalPreviewCard} from "../../../shared/models/goal/goal-preview-card.model";
import {GoalProgressComponentData} from "../../../shared/models/goal/goal-progress-component-data.model";
import {GoalDeleteDialogComponent} from "../../goal-delete-dialog/goal-delete-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {CommonUtil} from "../../shared/common-util";
import {GoalService} from "../../../shared/services/goal.service";

@Component({
    selector: 'nm-goal-preview-card',
    templateUrl: './goal-preview-card.component.html',
    styleUrls: ['./goal-preview-card.component.scss']
})
export class GoalPreviewCardComponent implements OnInit {

    @Input() goal!: GoalPreviewCard;

    @Output() onDelete = new EventEmitter<void>;

    goalProgressData!: GoalProgressComponentData;

    // заглушки с данными на время пока с бэка не приходят эти поля
    memberCount = 5;

    constructor(private router: Router, private deleteDialog: MatDialog, private goalService: GoalService) {
    }

    ngOnInit(): void {
        this.goalProgressData = new GoalProgressComponentData(
            this.goal.id,
            this.goal.type,
            this.goal.measurementUnit,
            this.goal.currentProgressValue,
            this.goal.finishProgressValue,
            this.goal.startDate,
            this.goal.finishDate,
            this.goal.status
        );
    }

    getTextClassByStatus() {
        return CommonUtil.getTextClassByBaseGoalStatus(this.goal.status);
    }

    openCard(goalId: string) {
        this.router.navigate(["/system/goal-card", goalId]);
    }

    delete(): void {
        const createDialogReference = this.deleteDialog.open(
            GoalDeleteDialogComponent,
            {panelClass: 'my-panel', data: {goalId: this.goal.id, goalName: this.goal.name}}
        );
        createDialogReference.afterClosed().subscribe((result: boolean) => {
            // TODO: 27.03.2024 добавить обработку ошибок
            if (result) {
                this.onDelete.emit()
            }
        });
    }

    refreshGoal() {
        this.goalService.getGoalById(this.goal.id).subscribe(
            response => {
                this.goal = response;
                this.goalProgressData = GoalProgressComponentData.createByGoalPreviewCard(this.goal);
            }
        );
    }

    /**
     * Алгоритм обрезки имени цели в превью-карточке
     */
    getGoalName() {
        const MAX_NAME_LENGTH = 55;
        if (this.goal.name.length < MAX_NAME_LENGTH) {
            return this.goal.name;
        }

        const ROW_LENGTH = 26;
        const ELLIPSIS_LENGTH = 2;
        let words = this.goal.name.split(" ");
        if (words[0].length > ROW_LENGTH) {
            return this.goal.name;
        }
        let firstRowLength = ROW_LENGTH;
        let secondRowLength = ROW_LENGTH;
        let resultGoalName = '';
        for (const word of words) {
            if (word.length > firstRowLength) {
                if (word.length > secondRowLength) {
                    let cutDisplacedWord = word.substring(0, secondRowLength - ELLIPSIS_LENGTH) + "...";

                    return resultGoalName.substring(0, resultGoalName.length).trim() + " " + cutDisplacedWord;
                }
                secondRowLength -= word.length + 1;
            }
            firstRowLength -= word.length + 1;
            resultGoalName += word + " ";
        }

        return resultGoalName;
    }
}
