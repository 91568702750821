import {Component, OnInit} from '@angular/core';
import {GoalCreateDto} from "../../../shared/models/goal/goal.create-dto";
import {GoalService} from "../../../shared/services/goal.service";
import {MeasurementUnitService} from "../../../shared/services/measurement-unit.service";
import {MeasurementUnit} from "../../../shared/models/measurement-unit.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {GoalValidationConstants} from "../../shared/validator/goal-validation-constants";
import {afterOrEqualCurrentDateValidator, startFinishDateValidator} from "../../shared/validator/goal-validator";
import {DateUtil} from "../../shared/date-util";
import {CommonUtil} from "../../shared/common-util";
import {Tag} from "../../../shared/models/tag.model";

@Component({
    selector: 'nm-goal-create',
    templateUrl: './goal-create.component.html',
    styleUrls: ['./goal-create.component.scss']
})
export class GoalCreateComponent implements OnInit {

    createForm!: FormGroup;

    NAME_MAX_LENGTH: number = GoalValidationConstants.NAME_MAX_LENGTH;
    DESCRIPTION_MAX_LENGTH: number = GoalValidationConstants.DESCRIPTION_MAX_LENGTH;

    measurementUnits: MeasurementUnit[] = [];
    activeGoalType: boolean = true;
    tagList: Tag[] = [];

    constructor(private goalService: GoalService,
                private measurementUnitService: MeasurementUnitService,
                private matDialogReference: MatDialogRef<boolean>) {
    }

    ngOnInit(): void {
        this.createForm = new FormGroup({
                nameForm: new FormControl(
                    '',
                    [
                        Validators.required,
                        Validators.maxLength(this.NAME_MAX_LENGTH)
                    ]
                ),
                startDateForm: new FormControl('', [afterOrEqualCurrentDateValidator()]),
                finishDateForm: new FormControl(
                    '',
                    [
                        Validators.required,
                        afterOrEqualCurrentDateValidator()
                    ]
                ),
                currentProgressValueForm: new FormControl('', [Validators.min(0)]),
                finishProgressValueForm: new FormControl(
                    '',
                    [
                        Validators.required,
                        Validators.min(0)
                    ]
                ),
                measurementUnitTitleForm: new FormControl('', []),
                descriptionForm: new FormControl(
                    '',
                    [Validators.maxLength(this.DESCRIPTION_MAX_LENGTH)]
                )
            },
            {
                validators: startFinishDateValidator()
            }
        );

        this.measurementUnitService.getMeasurementUnitList().subscribe(
            response => {
                this.measurementUnits = response;
                this.createForm.controls['measurementUnitTitleForm'].setValue(this.measurementUnits[0].title);
            }
        );
    }

    changeActiveGoalType(elemRef: any) {
        if (elemRef.classList.contains('simple-goal-toggle-button')) {
            this.activeGoalType = true;
        }

        if (elemRef.classList.contains('master-goal-toggle-button')) {
            this.activeGoalType = false;
        }
    }

    currentProgressValueSetDefault() {
        if (CommonUtil.isEmpty(this.createForm, 'currentProgressValueForm')) {
            this.createForm.controls['currentProgressValueForm']?.setValue(0);
        }
    }

    onSubmit() {
        const measurementUnit = MeasurementUnit.getMeasurementUnitByTitle(
            this.measurementUnits,
            this.createForm.get('measurementUnitTitleForm')?.value
        );
        const startDate: Date = this.createForm.get('startDateForm')?.value;
        const finishDate: Date = this.createForm.get('finishDateForm')?.value;

        const createDto: GoalCreateDto = new GoalCreateDto(
            this.createForm.get('nameForm')?.value.trim(),
            this.createForm.get('descriptionForm')?.value.trim(),
            DateUtil.toUtcDate(startDate),
            DateUtil.toUtcDate(finishDate),
            0,
            this.tagList.map(tag => tag.id),
            this.createForm.get('currentProgressValueForm')?.value,
            this.createForm.get('finishProgressValueForm')?.value,
            measurementUnit
        );

        this.goalService.createGoal(createDto).subscribe();
        this.matDialogReference.close(true);
    }

    updateTagList(tags: Tag[]): void {
        this.tagList = tags;
    }
}
