import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GoalProgressComponentData} from "../../../../shared/models/goal/goal-progress-component-data.model";
import {GoalStatus, GoalType} from "../../../../shared/models/goal/goal.model";
import {GoalService} from "../../../../shared/services/goal.service";
import {GoalProgressValueDto} from "../../../../shared/models/goal/goal-progress-value.dto";

const INITIAL_PROGRESS_INPUT_WIDTH: number = 72;
const MAX_PROGRESS_INPUT_LENGTH: number = 9;

@Component({
    selector: 'nm-goal-progress-section',
    templateUrl: './goal-progress-section.component.html',
    styleUrls: ['./goal-progress-section.component.scss']
})
export class GoalProgressSectionComponent implements OnInit {

    @Input() fullMode!: boolean;

    @Input() progressData!: GoalProgressComponentData;

    @Output() onAddProgressValue = new EventEmitter<void>;

    readonly PROGRESS_PERCENT_TO_SWITCH_TEXT_POSITION: number = 15;

    progressInputFlag: boolean = false;

    progressInputValue: number = 1;

    progressPercent!: number;

    constructor(private goalService: GoalService) {
    }

    ngOnInit(): void {
        this.progressPercent = this.getProgressPercent();
    }

    isGoal() {
        return this.progressData.goalType == GoalType.GOAL;
    }

    isMasterGoal() {
        return this.progressData.goalType == GoalType.MASTER_GOAL;
    }

    showProgressInput() {
        this.progressInputFlag = true;
    }

    addProgressInputValue() {
        this.progressInputFlag = false;
        if (this.progressInputValue != 0) {
            let value: number = this.progressInputValue;
            this.goalService.addProgressValue(this.progressData.goalId, new GoalProgressValueDto(value)).subscribe(
                () => {
                    this.progressData.currentProgressValue = this.progressData.currentProgressValue + value;
                    this.progressPercent = this.getProgressPercent();
                    this.onAddProgressValue.emit();
                }
            )
        }

        this.progressInputValue = 1;
    }

    cancel() {
        this.progressInputFlag = false;
        this.progressInputValue = 1;
    }

    calculateWidth(elemRef: any): void {
        if (elemRef.value.length <= 3) {
            elemRef.style.width = INITIAL_PROGRESS_INPUT_WIDTH + 'px';
            return;
        }
        elemRef.style.width = INITIAL_PROGRESS_INPUT_WIDTH + (elemRef.value.length) * 5 + 'px';
    }

    validateProgressInputLength(event: any) {
        const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
        if (allowedKeys.includes(event.key)) {
            return;
        }
        if (event.target.value.toString().length == MAX_PROGRESS_INPUT_LENGTH) {
            event.preventDefault();
        }
    }

    incrementInputProgressValue() {
        this.progressInputValue = this.progressInputValue + 1;
    }

    decrementInputProgressValue() {
        this.progressInputValue = this.progressInputValue - 1;
    }

    isGoalComplete(): boolean {
        return this.progressData.status.title === GoalStatus.COMPLETE;
    }

    getProgressPercent(): number {
        let percent: number = this.progressData.currentProgressValue / (this.progressData.finishProgressValue / 100);
        percent = Number(percent.toFixed(0));

        return percent > 100 ? 100 : percent;
    }
}
