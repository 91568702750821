import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AppComponent} from "../../app.component";
import {ValidationConstants} from "../../shared/validation-constants";
import {Router} from "@angular/router";

@Component({
    selector: 'nm-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    form!: FormGroup;

    constructor(private router: Router, private appComponent: AppComponent) {
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            'email': new FormControl(
                null,
                [
                    Validators.required,
                    Validators.email,
                    Validators.minLength(ValidationConstants.MIN_EMAIL_LENGTH),
                    Validators.maxLength(ValidationConstants.MAX_EMAIL_LENGTH)
                ]
            )
        });
    }

    onSubmit() {
        if (this.form.invalid) {
            this.appComponent.showError("Вы ввели неверную электронную почту");
        } else {
            this.appComponent.showError("Приложение находится на этапе разработки. Восстановление пароля временно не работает.");
        }
    }

    cancel() {
        this.router.navigate(['/login']);
    }
}
