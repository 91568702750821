<div class="tag-picker-main-div">
    <div class="tag-search-container">
        <div class="tag-div" *ngFor="let tag of selectedTags">
            <div class="tag-name-div" title="{{tag.name}}">
                <p class="tag-name">{{tag.name}}</p>
            </div>
            <img class="tag-close-icon-div" src="assets/cross-gray.svg" (click)="deleteTag(tag.id)" alt="cross-gray">
        </div>

        <div class="tag-input-div" *ngIf="selectedTags.length != getMaxNumberTag()">
            <input class="tag-input main-input"
                   type="text"
                   placeholder="Поиск по тегам"
                   [(ngModel)]="inputText"
                   (input)="searchTag()"
                   (focus)="onFocus()"
                   (blur)="showCombobox = false">
        </div>
    </div>

    <div class="combobox-list-div" *ngIf="showCombobox && selectedTags.length != getMaxNumberTag()">
        <div class="combobox-list-item-div" *ngFor="let tag of comboboxTags" (mousedown)="selectTag(tag)">
            {{tag.name}}
        </div>
    </div>
</div>