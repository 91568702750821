import {Component, OnInit} from '@angular/core';
import {GoalService} from "../../shared/services/goal.service";
import {Goal, GoalStatus} from "../../shared/models/goal/goal.model";
import {ActivatedRoute, Router} from "@angular/router";
import {GoalProgressComponentData} from "../../shared/models/goal/goal-progress-component-data.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {startFinishDateValidator} from "../shared/validator/goal-validator";
import {MeasurementUnitService} from "../../shared/services/measurement-unit.service";
import {MeasurementUnit} from "../../shared/models/measurement-unit.model";
import {GoalValidationConstants} from "../shared/validator/goal-validation-constants";
import {GoalVisibility} from "../../shared/models/goal/goal-visibility.model";
import {GoalVisibilityService} from "../../shared/services/goal-visibility.service";
import {Complexity} from "../../shared/models/complexity.model";
import {ComplexityService} from "../../shared/services/complexity.service";
import {GoalUpdateDto} from "../../shared/models/goal/goal.update-dto";
import {DateUtil} from "../shared/date-util";
import {MatDialog} from "@angular/material/dialog";
import {GoalDeleteDialogComponent} from "../goal-delete-dialog/goal-delete-dialog.component";
import {CommonUtil} from "../shared/common-util";
import {Tag} from "../../shared/models/tag.model";

@Component({
    selector: 'nm-goal-card',
    templateUrl: './goal-card.component.html',
    styleUrls: ['./goal-card.component.scss']
})
export class GoalCardComponent implements OnInit {

    NAME_MAX_LENGTH: number = GoalValidationConstants.NAME_MAX_LENGTH;
    DESCRIPTION_MAX_LENGTH: number = GoalValidationConstants.DESCRIPTION_MAX_LENGTH;

    goal!: Goal;

    goalProgressData!: GoalProgressComponentData;

    editForm: FormGroup = new FormGroup({});

    editMode: boolean = false;

    measurementUnits: MeasurementUnit[] = [];
    goalVisibilities: GoalVisibility[] = [];
    complexities: Complexity[] = [];
    editedTagList: Tag[] = [];

    constructor(
        private goalService: GoalService,
        private measurementUnitService: MeasurementUnitService,
        private goalVisibilityService: GoalVisibilityService,
        private complexityService: ComplexityService,
        private route: ActivatedRoute,
        private router: Router,
        private deleteDialog: MatDialog,
    ) {
    }

    ngOnInit(): void {
        this.initGoal();

        this.measurementUnitService.getMeasurementUnitList().subscribe(
            response => this.measurementUnits = response
        );
        this.goalVisibilityService.getGoalVisibilityList().subscribe(
            response => this.goalVisibilities = response
        );
        this.complexityService.getComplexityList().subscribe(
            response => this.complexities = response
        );
    }

    initGoal(): void {
        this.route.params.subscribe(params => {
                this.goalService.getGoalById(params["goalId"]).subscribe(
                    response => {
                        this.goal = response;
                        this.goalProgressData = GoalProgressComponentData.createByGoal(this.goal);
                    }
                );
            }
        );
    }

    edit(): void {
        this.editForm = this.createForm();
        this.editedTagList = this.goal.tagIds.slice();
        this.editMode = true;
    }

    onSubmit() {
        if (this.isFormNotChanged()) {
            this.editMode = false;
            return;
        }

        const measurementUnit = MeasurementUnit.getMeasurementUnitByTitle(
            this.measurementUnits,
            this.editForm.get('measurementUnitTitleForm')!.value
        );
        const visibility = GoalVisibility.getGoalVisibilityByTitle(
            this.goalVisibilities,
            this.editForm.get('visibilityForm')!.value
        );
        const complexity = Complexity.getComplexityByTitle(
            this.complexities,
            this.editForm.get('complexityForm')!.value
        );

        const updateDto: GoalUpdateDto = new GoalUpdateDto(
            this.editForm.get('nameForm')!.value.trim(),
            this.editForm.get('descriptionForm')!.value.trim(),
            this.getStartDate(),
            this.getFinishDate(),
            this.editedTagList.map(tag => tag.id),
            this.editForm.get('currentProgressValueForm')!.value,
            this.editForm.get('finishProgressValueForm')!.value,
            measurementUnit,
            visibility,
            complexity
        );

        this.goalService.updateGoal(updateDto, this.goal.id)
            .subscribe(response => {
                    this.goal = response;
                    this.goalProgressData = GoalProgressComponentData.createByGoal(this.goal);
                    this.editMode = false;
                }
            );
    }

    isFormNotChanged(): boolean {
        if (this.isTagsEdited()) {
            return false;
        }
        if (this.editForm.untouched || this.editForm.pristine) {
            return true;
        }

        return this.goal.name === this.editForm.get('nameForm')!.value
            && this.editForm.get('startDateForm')!.pristine
            && this.editForm.get('finishDateForm')!.pristine
            && this.goal.currentProgressValue === this.editForm.get('currentProgressValueForm')!.value
            && this.goal.finishProgressValue === this.editForm.get('finishProgressValueForm')!.value
            && this.goal.measurementUnit.title === this.editForm.get('measurementUnitTitleForm')!.value
            && this.goal.visibility.title === this.editForm.get('visibilityForm')!.value
            && this.goal.complexity.title === this.editForm.get('complexityForm')!.value
            && this.goal.description === this.editForm.get('descriptionForm')!.value;
    }

    isTagsEdited(): boolean {
        if (this.goal.tagIds.length != this.editedTagList.length) {
            return true;
        }

        return !this.goal.tagIds.every(tag => this.editedTagList.includes(tag));
    }

    currentProgressValueSetDefault() {
        if (CommonUtil.isEmpty(this.editForm, 'currentProgressValueForm')) {
            this.editForm.controls['currentProgressValueForm']?.setValue(0);
        }
    }

    isGoalComplete(): boolean {
        return this.goal.status.title === GoalStatus.COMPLETE;
    }

    getDisabledEditButtonTitle(): string {
        if (this.isGoalComplete()) {
            return "Цель в статусе '" + GoalStatus.COMPLETE + "' не редактируется"
        }

        return "";
    }

    getTextClassByStatus() {
        return CommonUtil.getTextClassByBaseGoalStatus(this.goal.status);
    }

    delete(): void {
        const createDialogReference = this.deleteDialog.open(
            GoalDeleteDialogComponent,
            {panelClass: 'my-panel', data: {goalId: this.goal.id, goalName: this.goal.name}}
        );
        createDialogReference.afterClosed().subscribe((result: boolean) => {
            // TODO: 27.03.2024 добавить обработку ошибок
            if (result) {
                this.router.navigate(["/system/goal-list"]);
            }
        });
    }

    refreshGoal() {
        this.initGoal();
    }

    isDescriptionBlank() {
        return this.goal.description == null || this.goal.description === '';
    }

    private createForm() {
        return new FormGroup({
                nameForm: new FormControl(
                    this.goal.name,
                    [
                        Validators.required,
                        Validators.maxLength(GoalValidationConstants.NAME_MAX_LENGTH)
                    ]
                ),
                currentProgressValueForm: new FormControl(
                    this.goal.currentProgressValue,
                    [Validators.min(0)]
                ),
                finishProgressValueForm: new FormControl(
                    this.goal.finishProgressValue,
                    [
                        Validators.required,
                        Validators.min(0)
                    ]
                ),
                measurementUnitTitleForm: new FormControl(this.goal.measurementUnit.title, []),
                descriptionForm: new FormControl(
                    this.goal.description,
                    [Validators.maxLength(GoalValidationConstants.DESCRIPTION_MAX_LENGTH)]
                ),
                visibilityForm: new FormControl(this.goal.visibility.title, []),
                complexityForm: new FormControl(this.goal.complexity.title, []),
                startDateForm: new FormControl(this.goal.startDate, [Validators.required]),
                finishDateForm: new FormControl(this.goal.finishDate, [Validators.required])
            },
            {
                validators: startFinishDateValidator()
            }
        );
    }

    /**
     * В input с датами при создании формы подставляются значения из цели, которая выбрана для редактирования.
     * Если эти даты пользователь не изменяет (pristine = true), то на бэк они передаются так как есть
     * Если пользователь меняет дату, то датапикер вставляет их в специфическом формате,
     * который нужно преобразовывать с помощью функции toUtcDate
     */
    private getStartDate(): Date {
        const startDateForm = this.editForm.get('startDateForm');

        return startDateForm!.pristine ? startDateForm!.value : DateUtil.toUtcDate(startDateForm!.value);
    }

    private getFinishDate(): Date {
        const finishDateForm = this.editForm.get('finishDateForm');

        return finishDateForm!.pristine ? finishDateForm!.value : DateUtil.toUtcDate(finishDateForm!.value);
    }

    updateTagList(tags: Tag[]) {
        this.editedTagList = tags;
    }
}
