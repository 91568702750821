import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SystemComponent} from "./system.component";
import {GoalListComponent} from "./goal-list/goal-list.component";
import {GoalCardComponent} from "./goal-card/goal-card.component";

const routes: Routes = [
    {
        path: 'system', component: SystemComponent, children: [
            {path: 'goal-list', component: GoalListComponent},
            {path: "goal-card/:goalId", component: GoalCardComponent}
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SystemRoutingModule {
}
