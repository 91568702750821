export class MeasurementUnit {

    constructor(public name: string, public title: string) {
    }

    public static getMeasurementUnitByTitle(measurementUnits: MeasurementUnit[], title: string): MeasurementUnit | null {
        const result = measurementUnits.find((measurementUnit) => measurementUnit.title === title);
        if (typeof result === "undefined") {
            return null;
        }

        return result;
    }
}
