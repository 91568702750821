import {Component, OnInit} from '@angular/core';
import {AuthTestGreetingService} from "../services/auth-test-greeting.service";
import {catchError, throwError} from "rxjs";
import {HttpErrorResponse, HttpResponse} from "@angular/common/http";

@Component({
    selector: 'nm-auth-test',
    templateUrl: './auth-test.component.html',
    styleUrls: ['./auth-test.component.scss']
})
export class AuthTestComponent implements OnInit {

    greetingMessage: string | null = 'no answer';

    constructor(private authTestService: AuthTestGreetingService) {
    }

    ngOnInit(): void {
        console.log("AuthTestComponent - ngOnInit");
    }

    getGreetingAll() {
        this.authTestService.hello()
            .pipe(catchError(this.handleError))
            .subscribe((value: HttpResponse<string>) => {
                this.greetingMessage = value.body;
            });
    }

    getGreetingUser() {
        this.authTestService.helloUser()
            .pipe(catchError(this.handleError))
            .subscribe((value: HttpResponse<string>) => {
                this.greetingMessage = value.body;
            });
    }

    getAdminGreeting() {
        this.authTestService.helloAdmin()
            .pipe(catchError(this.handleError))
            .subscribe((value: HttpResponse<string>) => {
                this.greetingMessage = value.body;
            });
    }

    // TODO обсудить и добавить корректную обработку ошибок
    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            console.error('Client-side or network error occurred:', error.error);
            alert('Client-side or network error occurred:' + error.error);
        } else {
            console.error(`Backend returned code ${error.status}, body was: `, error.error);
        }

        return throwError(() => new Error('Something bad happened; please try again later.'));
    }
}
