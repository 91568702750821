export class Complexity {

    constructor(public name: string, public title: string, public weight: number) {
    }

    public static getComplexityByTitle(complexities: Complexity[], title: string): Complexity | null {
        const result = complexities.find((complexity) => complexity.title === title);
        if (typeof result === "undefined") {
            return null;
        }

        return result;
    }
}