import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Tag} from "../../shared/models/tag.model";
import {TagService} from "../../shared/services/tag.service";
import {PageableFilterRequest} from "../../shared/models/http/request/pageable-filter-request";
import {TagFilter} from "../../shared/models/http/request/filter/tag-filter";
import {SortDirection} from "../../shared/models/http/request/pageable-request";


@Component({
    selector: 'nm-tag-picker',
    templateUrl: './tag-picker.component.html',
    styleUrls: ['./tag-picker.component.scss']
})
export class TagPickerComponent implements OnInit {

    /**
     * Компонент может работать в 2 режимах:
     * 1. Режим поиска тегов пользователя
     * 2. Режим поиска всех тегов системы
     */
    @Input() userTagSearchMode!: boolean;

    @Input() initialSelectedTags?: Tag[];

    @Output() onTagListChange = new EventEmitter<Tag[]>;

    selectedTags: Tag[] = [];

    inputText = "";

    showCombobox: boolean = false;

    comboboxTags: Tag[] = [];

    constructor(private tagService: TagService) {
    }

    ngOnInit(): void {
        if (this.initialSelectedTags != undefined) {
            this.selectedTags = this.initialSelectedTags.slice();
        }
    }

    /**
     * Количество тегов, которые можно выбирать пользователю
     */
    getMaxNumberTag(): number {
        return this.userTagSearchMode ? 6 : 3;
    }

    /**
     * Количество символов, необходимых для начала поиска
     */
    getStringLengthToStartSearch(): number {
        return this.userTagSearchMode ? 0 : 2;
    }

    searchTag(): void {
        if (this.inputText.length >= this.getStringLengthToStartSearch()) {
            this.tagService.getTagPageableByFilter(this.createRequestWithFilter()).subscribe(
                response => {
                    this.comboboxTags = this.removeAlreadySelectedFromTagArray(response.content);
                    this.showCombobox = true;
                });
        } else {
            this.showCombobox = false;
        }
    }

    selectTag(tag: Tag): void {
        if (this.selectedTags.length >= this.getMaxNumberTag() || this.isAlreadySelected(tag)) {
            return;
        }
        this.selectedTags.push(tag);
        this.onTagListChange.emit(this.selectedTags);
        this.inputText = "";
        this.comboboxTags = [];
        this.showCombobox = false;
    }

    deleteTag(tagId: string): void {
        let positionElement = this.selectedTags.findIndex(tag => tag.id === tagId);

        this.selectedTags.splice(positionElement, 1);
        this.onTagListChange.emit(this.selectedTags);
    }

    onFocus(): void {
        if (this.userTagSearchMode) {
            if (this.inputText.length === 0) {
                this.searchTag();
            }
        }
        if (this.inputText.length >= this.getStringLengthToStartSearch()) {
            this.showCombobox = true;
        }
    }

    private isAlreadySelected(selectedTag: Tag): boolean {
        let positionElement = this.selectedTags.findIndex(tag => tag.id === selectedTag.id);

        return positionElement != -1;
    }

    private removeAlreadySelectedFromTagArray(tagArray: Tag[]): Tag[] {
        let selectedTagIds = this.selectedTags.map(tag => tag.id);

        return tagArray.filter(tag => !selectedTagIds.some(id => id === tag.id));
    }

    private createRequestWithFilter(): PageableFilterRequest<TagFilter> {
        let filter = new TagFilter();
        filter.currentUserUsed = this.userTagSearchMode;
        filter.searchString = this.inputText;

        let request = new PageableFilterRequest<TagFilter>();

        request.filters = filter;
        request.sort = ['name'];
        request.size = 10;
        request.sortDirection = SortDirection.ASC;

        return request;
    }
}