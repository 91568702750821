import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {BaseGoalStatus} from "../models/base-goal-status.model";

const BASE_GOAL_STATUS_ROOT_URL: string = environment.backendCoreURL + "/base-goal-statuses";

@Injectable()
export class BaseGoalStatusService {

    constructor(private http: HttpClient) {
    }

    getBaseGoalStatusList(): Observable<BaseGoalStatus[]> {
        const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf8'});

        return this.http.get<BaseGoalStatus[]>(BASE_GOAL_STATUS_ROOT_URL, {headers: headers});
    }
}
