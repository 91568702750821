export class PageableRequest {

    page: number = 0;
    size: number = 8;
    sort: string[] = [];
    sortDirection: SortDirection = SortDirection.ASC;

    public convertPageableToUrl(): string {
        let url = `?page=${this.page}&size=${this.size}`;

        this.sort?.forEach(sortElem => {
            url += `&sort=${sortElem}`;
        })

        url += `,${this.sortDirection}`

        return url;
    }

    public changeSortDirection() {
        if (this.sortDirection == SortDirection.ASC) {
            this.sortDirection = SortDirection.DESC;
        } else {
            this.sortDirection = SortDirection.ASC;
        }
    }
}

export enum SortDirection {

    ASC = "asc",
    DESC = "desc"
}
