<div class="goal-card-div">
    <div class="header-div">
        <div class="tag-name-div" *ngIf="goal.tagIds.length == 0">
            Теги не были добавлены
        </div>
        <div class="tag-div" *ngFor="let tag of goal.tagIds">
            <div class="tag-name-div" title="{{tag.name}}">{{tag.name}}</div>
        </div>

        <button class="dots-button secondary-button" [matMenuTriggerFor]="menu">
            <img src='assets/dots-icon.svg' alt="dots-icon">
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="delete()">
                <img class="button-icon" src='assets/trash-icon.svg' alt="trash-icon">
                <span>Удалить</span>
            </button>
        </mat-menu>
    </div>

    <span class="goal-name-text" (click)="openCard(goal.id)" [title]="goal.name">{{getGoalName()}}</span>

    <nm-goal-progress-section [fullMode]=false [progressData]=goalProgressData (onAddProgressValue)="refreshGoal()">
    </nm-goal-progress-section>

    <div class="footer">
        <div class="dates-div">
            <img class="dates-img" src="assets/clock-icon.svg" alt="clock-icon">
            <p class="dates-text" [ngClass]="getTextClassByStatus()" [attr.title]="goal.status.title">
                {{goal.startDate | date : 'dd.MM.yyyy'}} - {{goal.finishDate | date : 'dd.MM.yyyy'}}
            </p>
        </div>

        <div class="footer-right-group">
            <div class="complexity-div" [ngSwitch]="goal.complexity.name">
                <img *ngSwitchCase="'SIMPLE'" class="complexity-icon"
                     src='assets/complexity/complexity-simple-icon.svg' alt="complexity-simple-icon">
                <img *ngSwitchCase="'NORMAL'" class="complexity-icon"
                     src='assets/complexity/complexity-normal-icon.svg' alt="complexity-normal-icon">
                <img *ngSwitchCase="'HARD'" class="complexity-icon"
                     src='assets/complexity/complexity-hard-icon.svg' alt="complexity-hard-icon">
            </div>

            <div class="visibility-group-div">
                <p class="visibility-members-text">{{memberCount}}</p>
                <div class="visibility-icon-div" [ngSwitch]="goal.visibility.name">
                    <img *ngSwitchCase="'PRIVATE'" class="visibility-icon"
                         src='assets/visibility/private-icon.svg' alt="private-icon">
                    <img *ngSwitchCase="'FRIEND'" class="visibility-icon"
                         src='assets/visibility/friend-icon.svg' alt="friend-icon">
                    <img *ngSwitchCase="'PUBLIC'" class="visibility-icon"
                         src='assets/visibility/public-icon.svg' alt="public-icon">
                </div>
            </div>
        </div>
    </div>
</div>
