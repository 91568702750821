import {CommonFilter} from "../common-filter";

export class TagFilter implements CommonFilter {

    searchString: string = '';

    currentUserUsed: boolean = true;

    convertFilterToUrl(): string {
        return `&currentUserUsed=${this.currentUserUsed}&searchString=${this.searchString}`;
    }
}