import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {SharedModule} from "../shared/shared.module";
import {SystemRoutingModule} from "./system-routing.module";
import {SystemComponent} from './system.component';
import {GoalListComponent} from './goal-list/goal-list.component';
import {TagService} from "../shared/services/tag.service";
import {GoalPreviewCardComponent} from './goal-list/goal-preview-card/goal-preview-card.component';
import {GoalService} from "../shared/services/goal.service";
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {getRusPaginatorIntl} from "./rus-paginator-intl";
import {GoalCardComponent} from './goal-card/goal-card.component';
import {MatDialogModule} from "@angular/material/dialog";
import {GoalCreateComponent} from './goal-list/goal-create/goal-create.component';
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MeasurementUnitService} from "../shared/services/measurement-unit.service";
import {MatMenuModule} from "@angular/material/menu";
import {GoalProgressSectionComponent} from './shared/components/goal-progress/goal-progress-section.component';
import {TagPickerComponent} from './tag-picker/tag-picker.component';
import {GoalDeleteDialogComponent} from './goal-delete-dialog/goal-delete-dialog.component';
import {GoalVisibilityService} from "../shared/services/goal-visibility.service";
import {ComplexityService} from "../shared/services/complexity.service";
import {BaseGoalService} from "../shared/services/base-goal.service";
import {BaseGoalStatusService} from "../shared/services/base-goal-status.service";

@NgModule({
    declarations: [
        SystemComponent,
        GoalListComponent,
        GoalPreviewCardComponent,
        GoalCreateComponent,
        GoalCardComponent,
        GoalProgressSectionComponent,
        GoalDeleteDialogComponent,
        TagPickerComponent
    ],
    imports: [
        CommonModule,
        SystemRoutingModule,
        SharedModule,
        MatPaginatorModule,
        MatDialogModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatMenuModule
    ],
    providers: [
        TagService,
        BaseGoalService,
        GoalService,
        MeasurementUnitService,
        BaseGoalStatusService,
        GoalVisibilityService,
        ComplexityService,
        {provide: MatPaginatorIntl, useValue: getRusPaginatorIntl()},
        {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'}
    ]
})
export class SystemModule {
}
