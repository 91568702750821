import {Goal, GoalType} from "./goal.model";
import {MeasurementUnit} from "../measurement-unit.model";
import {BaseGoalStatus} from "../base-goal-status.model";
import {GoalPreviewCard} from "./goal-preview-card.model";

/**
 * Модель для передачи данных в goal-progress-section.component
 */
export class GoalProgressComponentData {

    constructor(
        public goalId: string,
        public goalType: GoalType,
        public measurementUnit: MeasurementUnit,
        public currentProgressValue: number,
        public finishProgressValue: number,
        public startDate: Date,
        public finishDate: Date,
        public status: BaseGoalStatus
        ) {
    }

    public static createByGoal(goal: Goal) {
        return new GoalProgressComponentData(
            goal.id,
            goal.type,
            goal.measurementUnit,
            goal.currentProgressValue,
            goal.finishProgressValue,
            goal.startDate,
            goal.finishDate,
            goal.status
        );
    }

    public static createByGoalPreviewCard(goal: GoalPreviewCard) {
        return new GoalProgressComponentData(
            goal.id,
            goal.type,
            goal.measurementUnit,
            goal.currentProgressValue,
            goal.finishProgressValue,
            goal.startDate,
            goal.finishDate,
            goal.status
        );
    }
}